import { type SEOHandle } from '@nasa-gcn/remix-seo'
import {
	type ActionFunctionArgs,
	type HeadersFunction,
	json,
	type LinksFunction,
	type LoaderFunctionArgs,
	type MetaFunction,
	redirect,
} from '@remix-run/node'
import { Link, useFetcher, useLoaderData } from '@remix-run/react'
import { PlusCircle, Search } from 'lucide-react'
import React from 'react'
import { serverOnly$ } from 'vite-env-only/macros'

import { Brands } from '#app/components/brands.tsx'
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx'
import { FeaturedCarListing } from '#app/components/featured-car-listing.tsx'
import { MapMain } from '#app/components/map-main.js'
import { PricingBeta } from '#app/components/pricingbeta.tsx'
import { Selectors } from '#app/components/selectors.tsx'
import Testimonials from '#app/components/testimonials.js'
import { Tools } from '#app/components/tools.tsx'
import { prisma } from '#app/utils/db.server.ts'
import {
	invariantResponse,
	reuseUsefulLoaderHeaders,
} from '#app/utils/misc.tsx'
import {
	// combineServerTimings,
	makeTimings,
	time,
} from '#app/utils/timing.server.ts'
import { useOptionalUser } from '#app/utils/user.ts'

export const headers: HeadersFunction = reuseUsefulLoaderHeaders

export const links: LinksFunction = () => {
	return [
		{
			rel: 'preload',
			href: 'https://carregados.com.br/cdn-cgi/image/height=420,width=480,format=auto,fit=crop/img/bannerm.webp',
			as: 'image',
		},
		{
			rel: 'preload',
			href: 'https://carregados.com.br/cdn-cgi/image/height=420,width=640,format=auto,fit=crop/img/bannerm.webp',
			as: 'image',
		},
	]
}

export const meta: MetaFunction = () => [
	{ title: 'Carregados - Marketplace de Carros Elétricos' },
	{
		name: 'description',
		content:
			'Encontre o carro elétrico perfeito para você. Venda seu carro elétrico rapidamente e sem complicações. Carregados é o maior marketplace exclusivo para carros elétricos no Brasil.',
	},
	{ itemProp: 'name', content: 'Carregados - Marketplace de Carros Elétricos' },
	{
		itemProp: 'description',
		content:
			'Encontre o carro elétrico perfeito para você. Venda seu carro elétrico rapidamente e sem complicações. Carregados é o maior marketplace exclusivo para carros elétricos no Brasil.',
	},
	{ itemProp: 'image', content: 'https://carregados.com.br/img/og-main.jpg' },
	{ property: 'og:url', content: 'https://carregados.com.br' },
	{ property: 'og:type', content: 'website' },
	{
		property: 'og:title',
		content: 'Carregados - Marketplace de Carros Elétricos',
	},
	{
		property: 'og:description',
		content:
			'Encontre o carro elétrico perfeito para você. Venda seu carro elétrico rapidamente e sem complicações. Carregados é o maior marketplace exclusivo para carros elétricos no Brasil.',
	},
	{
		property: 'og:image',
		content: 'https://carregados.com.br/img/og-main.jpg',
	},
	{ name: 'twitter:card', content: 'summary_large_image' },
	{
		name: 'twitter:title',
		content: 'Carregados - Marketplace de Carros Elétricos',
	},
	{
		name: 'twitter:description',
		content:
			'Encontre o carro elétrico perfeito para você. Venda seu carro elétrico rapidamente e sem complicações. Carregados é o maior marketplace exclusivo para carros elétricos do Brasil.',
	},
	{
		name: 'twitter:image',
		content: 'https://carregados.com.br/img/og-main.jpg',
	},
	{
		name: 'twitter:image:alt',
		content: 'Carregados - Marketplace de Carros Elétricos',
	},
	{
		tagName: 'link',
		rel: 'canonical',
		href: 'https://carregados.com.br',
	},
]

export async function loader({ request }: LoaderFunctionArgs) {
	const timings = makeTimings('index loader') // <-- 1. Setup Timings

	const allMakers = await time(
		() =>
			prisma.car.groupBy({
				by: ['maker'],
				where: {
					isInactive: {
						equals: true,
					},
				},
				_count: {
					maker: true,
				},
			}),
		{ timings, type: 'maker listings' },
	)

	invariantResponse(allMakers, 'allMakers not found', { status: 400 })

	const carListings = await time(
		() =>
			prisma.car.findMany({
				take: 9,
				select: {
					id: true,
					maker: true,
					model: true,
					fabricationYear: true,
					modelYear: true,
					state: true,
					city: true,
					price: true,
					version: true,
					odometer: true,
					url: true,
					isPaid: true,
					featuredAtMainPage: true,
					images: {
						select: {
							id: true,
						},
						where: {
							order: 0,
						},
					},
				},
				where: {
					isInactive: false,
					isRemoved: false,
				},
				orderBy: [
					{
						isPaid: 'desc',
					},
					{
						featuredAtMainPage: 'desc',
					},
					{
						createdAt: 'desc',
					},
				],
			}),
		{ timings, type: 'initial car listings' },
	)

	invariantResponse(carListings, 'carListings not found', { status: 400 })

	//count all cars
	const totalNumberOfCars = await time(
		() =>
			prisma.car.count({
				where: {
					isInactive: false,
					isRemoved: false,
				},
			}),
		{ timings, type: 'total number of cars' },
	)

	const allModels = await time(
		() =>
			prisma.car.groupBy({
				by: ['model'],
				where: {
					isInactive: false,
				},
				_count: {
					model: true,
				},
			}),
		{ timings, type: 'models listings' },
	)

	invariantResponse(allModels, 'allModels not found', { status: 400 })

	let allStates = await time(
		() =>
			prisma.car.groupBy({
				where: {
					isInactive: false,
				},
				by: ['state'],
				_count: {
					state: true,
				},
			}),
		{ timings, type: 'states listings' },
	)

	//capitalize first letter of each state, sort alphabetically and uppercase betweem parentheses
	allStates = allStates.map((state: any) => {
		return {
			state: `${state.state[0].toUpperCase()}${state.state.slice(1)}`,
			_count: state._count,
		}
	})

	invariantResponse(allStates, 'allStates not found', { status: 400 })

	//fill comboboxes
	const makers = allMakers.map((maker: any) => {
		return {
			value: `${maker.maker}`,
			label: `${maker.maker}`,
			count: `(${maker._count.maker})`,
			title: 'Marca',
		}
	})

	//add all to makers
	makers.unshift({
		value: '',
		label: 'Todos',
		count: '',
		title: 'Marca',
	})

	const models = allModels.map((model: any) => {
		return {
			value: `${model.model}`,
			label: `${model.model}`,
			count: `(${model._count.model})`,
			title: 'Modelo',
		}
	})

	//add all to models
	models.unshift({
		value: '',
		label: 'Todos',
		count: '',
		title: 'Modelo',
	})

	const states = allStates.map((state: any) => {
		return {
			value: `${state.state}`,
			label: `${state.state}`,
			count: `(${state._count.state})`,
			title: 'Localização',
		}
	})

	//add all to states
	states.unshift({
		value: '',
		label: 'Todos',
		count: '',
		title: 'Localização',
	})

	return json(
		{ carListings, makers, models, states, totalNumberOfCars },
		{
			headers: {
				'Cache-Control': 'private, max-age=3600',
				'Server-Timing': timings.toString(),
			},
		},
	)
}

export async function action({ request }: ActionFunctionArgs) {
	const formData = await request.formData()
	const marca = formData.get('marca')
	const modelo = formData.get('modelo')
	const localizacao = formData.get('localizacao')

	let query = new URLSearchParams()
	if (marca) query.append('marca', marca.toString())
	if (modelo) query.append('modelo', modelo.toString())
	if (localizacao) query.append('estado', localizacao.toString())

	if (query) {
		return redirect(`/carros?${query}`)
	} else {
		return redirect(`/carros`)
	}
}

export default function Index() {
	const { carListings, makers, models, states, totalNumberOfCars } =
		useLoaderData<typeof loader>()
	const fetcher = useFetcher()
	const user = useOptionalUser()

	const [selectedMaker, setSelectedMaker] = React.useState('')
	const [selectedModel, setSelectedModel] = React.useState('')
	const [selectedState, setSelectedState] = React.useState('')
	const [filteredMaker, setFilteredMaker] = React.useState(makers)
	const [filteredModel, setFilteredModel] = React.useState(models)
	const [filteredState, setFilteredState] = React.useState(states)

	interface FetcherDataType {
		makers: any
		models: any
		states: any
	}

	React.useEffect(() => {
		fetcher.load(
			`/resources/options?maker=${selectedMaker}&model=${selectedModel}&state=${selectedState}`,
		)
	}, [selectedMaker, selectedModel, selectedState])

	React.useEffect(() => {
		if (
			typeof fetcher.data === 'object' &&
			fetcher.data !== null &&
			'makers' in fetcher.data &&
			'models' in fetcher.data &&
			'states' in fetcher.data
		) {
			const data = fetcher.data as FetcherDataType
			setFilteredMaker(data.makers)
			setFilteredModel(data.models)
			setFilteredState(data.states)
		}
	}, [fetcher.data])

	return (
		<>
			<main className="mx-auto mt-3.5 flex min-h-screen w-full flex-col bg-gray-50 dark:bg-background">
				<section className="overflow-crop relative -mt-3.5 h-[420px] md:h-[420px]">
					<picture>
						<source
							media="(max-width: 320px)"
							srcSet="https://carregados.com.br/cdn-cgi/image/height=420,width=320,format=auto,fit=crop/img/bannerm.webp"
						/>
						<source
							media="(max-width: 480px)"
							srcSet="https://carregados.com.br/cdn-cgi/image/height=420,width=480,format=auto,fit=crop/img/bannerm.webp"
						/>
						<source
							media="(max-width: 640px)"
							srcSet="https://carregados.com.br/cdn-cgi/image/height=420,width=640,format=auto,fit=crop/img/bannerm.webp"
						/>
						<img
							src="https://carregados.com.br/cdn-cgi/image/width=920,fit=scale-down,format=auto/img/banner.webp"
							alt="Banner Carregados"
							className="h-full w-full object-cover object-center"
						/>
					</picture>
					<div className="absolute bottom-9 left-6 lg:left-1/2 lg:top-48 lg:-ml-[520px]">
						<div className="flex flex-col space-y-6">
							<span className="flex flex-col">
								<h1 className="text-4xl font-bold text-white">Carregados ⚡</h1>
								<h2 className="text-xl font-semibold text-white">
									O maior marketplace{' '}
									<span className="italic tracking-wide underline underline-offset-4">
										exclusivo
									</span>{' '}
									para carros elétricos no Brasil
								</h2>
							</span>
							<div className="hidden lg:block">
								<Selectors
									makers={filteredMaker}
									models={filteredModel}
									states={filteredState}
									selectedMaker={selectedMaker}
									onMakerChange={setSelectedMaker}
									selectedModel={selectedModel}
									onModelChange={setSelectedModel}
									selectedState={selectedState}
									onStateChange={setSelectedState}
								/>
							</div>
							<span className="flex gap-8 underline-offset-2">
								<Link className="hidden md:flex" prefetch="intent" to="/carros">
									<span className="gap-1 flex items-center font-semibold text-white underline hover:text-gray-200">
										<Search className="w-4 h-4 mr-1" />
										Carros Elétricos à Venda
									</span>
								</Link>
								<Link className="flex md:hidden" prefetch="intent" to="/carros">
									<span className="gap-1 flex items-center font-semibold text-white underline hover:text-gray-200">
										<Search className="w-4 h-4 mr-1" />
										Carros à Venda
									</span>
								</Link>
								<Link
									className="hidden md:block"
									to={`${
										user ? `usuario/${user.username}/carros/novo` : '/login'
									}`}
								>
									<span className="gap-1 flex items-center font-semibold text-white underline hover:text-gray-200">
										<PlusCircle className="w-4 h-4 mr-1" />
										Anunciar Carro Elétrico
									</span>
								</Link>
								<Link
									className="block md:hidden"
									to={`${
										user ? `usuario/${user.username}/carros/novo` : '/login'
									}`}
								>
									<span className="gap-1 flex items-center font-semibold text-white underline hover:text-gray-200">
										<PlusCircle className="w-4 h-4 mr-1" />
										Anunciar
									</span>
								</Link>
							</span>
						</div>
					</div>
				</section>
				<Brands totalNumberOfCars={totalNumberOfCars} />
				<section className="mx-auto max-w-6xl px-6 py-20 sm:px-6 lg:px-8">
					<div className="mx-auto text-center">
										<h2 className="inline-block rounded-full bg-orange-100 px-4 py-2 text-sm font-semibold text-orange-600 dark:bg-orange-900 dark:text-orange-300 md:text-base">
						Carros Elétricos à Venda
					</h2>
					</div>
					<p className="mt-6 text-center text-2xl font-bold tracking-tight text-primary dark:text-white sm:text-4xl lg:text-5xl">
						Destaques
					</p>{' '}
					<p className="mt-4 text-center text-base font-normal text-muted-foreground">
						Carros elétricos são o <span className="font-bold">futuro</span>.
						Encontre a <span className="font-bold">oportunidade perfeita</span>{' '}
						para você.
					</p>
					<div className="mt-8 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
						{carListings.map((carListing) => (
							<div
								key={carListing.id}
								className="overflow-hidden rounded-lg shadow-md"
							>
								<Link
									prefetch="intent"
									to={`/carros/${carListing.url}${carListing.id}`}
									className="block h-full"
								>
									<FeaturedCarListing data={carListing} />
								</Link>
							</div>
						))}
					</div>
				</section>
				<PricingBeta />
				{/* <Summary /> */}
				<MapMain />
				{/* <MapaRemain /> */}
				<Tools />
			</main>
		</>
	)
}

export const handle: SEOHandle = {
	//@ts-ignore
	getSitemapEntries: serverOnly$(async request => {
		const lastUpdatedOfAllCars = await prisma.car.findMany({
			select: {
				updatedAt: true,
			},
			orderBy: {
				updatedAt: 'desc',
			},
			take: 1,
		})

		return [
			{
				route: '',
				priority: 0.7,
				changefreq: 'daily',
				lastmod:
					lastUpdatedOfAllCars &&
					lastUpdatedOfAllCars[0]?.updatedAt.toISOString(),
			},
		]
	}),
}

export function ErrorBoundary() {
	return <GeneralErrorBoundary />
}
