import { Avatar, AvatarFallback, AvatarImage } from "#app/components/ui/avatar.tsx"
import { Card, CardContent, CardHeader } from "#app/components/ui/card.tsx"
import { Star } from "lucide-react"

const Testimonial = ({ name, car, image, review, date }: { name: string, car: string, image: string, review: string, date: string }) => (
  <Card className="w-full">
    <CardHeader className="flex flex-row items-center gap-4">
      <Avatar className="w-16 h-16">
        <AvatarImage alt={name} src={image} />
        <AvatarFallback>{name.split(' ').map(n => n[0]).join('')}</AvatarFallback>
      </Avatar>
      <div className="flex flex-col">
        <h3 className="text-lg font-semibold">{name}</h3>
        <p className="text-sm text-muted-foreground">{car}</p>
      </div>
    </CardHeader>
    <CardContent>
      <div className="flex mb-2">
        {[...Array(5)].map((_, i) => (
          <Star key={i} className="w-5 h-5 fill-yellow-500 text-yellow-500" />
        ))}
      </div>
      <p className="text-sm text-muted-foreground mb-4">{review}</p>
      <div className="flex items-center justify-end text-xs text-primary">
        <span className="font-semibold">Venda confirmada</span>
      </div>
    </CardContent>
  </Card>
)

export default function Component() {
  const testimonials = [
    {
      name: "Tiago Neves",
      car: "Tesla Model 3",
      image: "/img/tneves.png",
      review: "Recentemente, anunciei meu Tesla Model 3 no carregados.com.br, e a experiência foi espetacular. A equipe de suporte foi incrivelmente prestativa, orientando-me em cada etapa do processo. Para minha surpresa, o carro foi vendido muito mais rápido do que eu esperava. Foi um processo tranquilo e eficiente, e recomendo muito essa plataforma para quem deseja vender seu veículo elétrico de forma rápida e sem complicações.",
      date: "Maio 15, 2023"
    },
    {
      name: "Ana Paula Rossi",
      car: "GWM Ora 3",
      image: "/img/aprossi.png",
      review: "Vendi meu Ora 3 no Carregados de forma rápida e fácil. O processo foi simples e seguro, e a plataforma me ajudou a encontrar um comprador em poucos dias. Recomendo a todos que queiram vender seus dispositivos sem complicações.",
      date: "Junho 3, 2023"
    },
    {
      name: "Sérgio Oliveira",
      car: "BYD Dolphin Plus",
      image: "/img/soliveira.png",
      review: "Vendi meu BYD Dolphin Plus no carregados.com.br e estou muito satisfeito! Em poucos dias, encontrei um comprador graças à visibilidade do site. O processo foi rápido, seguro e sem complicações. Recomendo a plataforma para quem quer vender.",
      date: "April 22, 2023"
    }
  ] 

  return (
    <div className="container mx-auto py-20 px-0 sm:px-4">
      <h2 className="text-4xl font-bold text-center mb-8">O que nossos clientes dizem</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:px-0">
        {testimonials.map((testimonial, index) => (
          <Testimonial key={index} {...testimonial} />
        ))}
      </div>
    </div>
  )
}