import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '#app/components/ui/accordion.tsx'

interface FAQProps {
	question: string
	answer: string
	value: string
}

const FAQList: FAQProps[] = [
	{
		question:
			'Meu anúncio tem uma data de validade ou ele continuará publicado até que eu decida removê-lo?',
		answer:
			'Não, seu anúncio não tem uma data de validade. Ele continuará publicado até que você decida desativá-lo.',
		value: 'item-1',
	},
	{
		question:
			'Há custos recorrentes para manter meu anúncio de carro elétrico ativo?',
		answer:
			'Não, há apenas uma cobrança única para cada anúncio, independentemente do tempo que permaneça ativo. O valor é fixo e inclui todos os benefícios oferecidos pelo Carregados.',
		value: 'item-2',
	},
	// {
	// 	question: 'Como a divulgação do meu anúncio de carro elétrico é feita?',
	// 	answer:
	// 		'Além de publicarmos seu anúncio em nosso portal, fazemos a divulgação nas redes sociais e por e-mail, aumentando as chances de seu carro ser visto por potenciais compradores.',
	// 	value: 'item-3',
	// },
	{
		question:
			'Posso desativar meu anúncio de carro elétrico a qualquer momento?',
		answer:
			'Sim, você tem total controle sobre seus anúncios e pode desativá-los a qualquer momento.',
		value: 'item-4',
	},
	{
		question:
			'Se eu perceber que cometi um erro nas informações do meu anúncio, posso corrigi-lo sem custos adicionais?',
		answer:
			'Sim, você pode alterar as imagens e corrigir alguns dados do seu anúncio a partir do painel administrativo sem nenhum custo extra.',
		value: 'item-5',
	},
]

export const FAQ = () => {
	return (
		<section
			id="faq"
			className="w-full px-6 py-12 md:px-0"
		>
			<div className="mx-auto w-full max-w-6xl">
				<h2 className="mb-4 text-2xl font-bold md:text-4xl">
					Perguntas{' '}
					<span className="bg-gradient-to-b from-primary/60 to-primary bg-clip-text text-transparent">
						Frequentes
					</span>
				</h2>

				<Accordion type="single" collapsible className="AccordionRoot w-full">
					{FAQList.map(({ question, answer, value }: FAQProps) => (
						<AccordionItem key={value} value={value}>
							<AccordionTrigger className="text-left font-bold">
								{question}
							</AccordionTrigger>

							<AccordionContent>{answer}</AccordionContent>
						</AccordionItem>
					))}
				</Accordion>

				{/* <h3 className="font-medium mt-4">
        Still have questions?{" "}
        <Link
          to="/"	
          className="text-primary transition-all border-primary hover:border-b-2"
        >
          Contact us
        </Link>
      </h3> */}
			</div>
		</section>
	)
}
